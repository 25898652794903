/*
 * @Author: zsk
 * @Date: 2023-03-31 16:16:39
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-04-03 14:30:05
 */
/*全局公共方法*/
const Utils = {
  notNull(obj) {
    if (obj == null) return false
    if (Array.isArray(obj)) {
      if (obj.length) {
        return true
      }
    } else if (Object.prototype.toString.call(obj) === '[object Object]' || Object.prototype.toString.call(obj) === '[object String]') {
      if (Object.keys(obj).length) {
        return true
      }
    }
    return false
  }
}

export default Utils
