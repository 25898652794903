// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import zhHans from 'vuetify/lib/locale/zh-Hans'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
  lang: {
    locales: { zhHans },
    current: 'zhHans',
    icon: 'mdi'
  }
}

export default new Vuetify(opts)