/*
 * @Author: zsk
 * @Date: 2023-02-10 09:40:51
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-04-03 13:43:25
 */
/*vuex 状态管理器*/

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isLogin: localStorage.getItem('BJ.token') ? true : false, //是否登录
    messageList: localStorage.getItem('messageList') ? localStorage.getItem('messageList') : [], //信息列表
    refresh:false,//记录页面是否刷新，每次刷新完后立即置为false
    zoom:false,
    username: 'BJ.username', // 用户名
    keyToken: 'BJ.token', // token key
    tokenPre: 'Bearer ', // token 前缀
    tokenExpire: 'CCS.expire', // token 有效期
  },

  getters: {
    // count: state => state.count
    isLogin: state => state.isLogin,
    messageList: state => state.messageList
  },

  mutations: {
    commitRefresh: (state, value) => state.refresh = value,
    commitZoom: (state, value) => state.zoom = value,
    commitIsLogin: (state, value) => state.isLogin = value
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    sendSms:({ commit, state }, params) => {
      console.log(params, '.')
      // let params = {
      //   mobileTel:  value,//手机号
      // }
      return Vue.prototype.$http.get(Vue.prototype.$apis.sendSms, params)
    }
  }
});
export default store;

