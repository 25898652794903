/*
 * @Author: zsk
 * @Date: 2023-03-31 14:44:00
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-03-31 16:10:41
 */
import Axios from "axios";
import store from "../store";
// import { Toast } from "vant";

let API = location.origin + '/api'
// store.commit('commitBaseURL', API)
const Request = Axios.create({
  baseURL: API,
  timeout: 90000,
  headers: {
    'content-type': 'application/json; charset=utf-8',
  },
});

Request.interceptors.request.use((config) => {
  let token = 'Bearer ' + window.localStorage.getItem(store.state.keyToken)
  if (token) {
    config.headers['Authorization'] = token
  }
  // console.log(config.url)
  return config
}, (err) => {
  // Toast('错误的传参')
  return Promise.reject(err)
})

Request.interceptors.response.use((res) => {
  if (res.data == null) return Promise.resolve(res)
  let msg = res.data.message
  if (res.data.success) {
    if (msg == null || msg === '') {
      return Promise.resolve(res)
    } else {
      // Toast(msg)
      return Promise.resolve(res)
    }
  } else {
    // if (msg) Toast(msg)
    return Promise.reject(res)
  }
}, (err) => {
  let status = 0
  let msg = ''
  if (err && err.response) {
    status = err.response.status
    msg = err.response.data.message
  }
  // 捕捉token过期时,跳转到登录页
  if (status === 401) {
    if (msg !== null || msg !== '') {
      // Toast("请重新登录！")
      localStorage.clear()
      store.commit("commitIsLogin", false)
    }
    /* router.replace('/login') */
    return
  } else if (status === 500) {
    msg = '系统异常, 请联系管理员查看服务器日志'
  }
  if (msg === '') {
    msg = '网络异常'
  }
  // Toast(msg)
  return Promise.reject(err)
})


export default Request;
