import Request from "./request";
// import {showLoading, closeLoading} from "./loading";



const Http = {
  get: (url, param) => {
    let params = Object.assign({source: 'h5'}, param)
    const suffix = Object.keys(params).map(name => {
      return `${name}=${params[name]}`
    }).join('&')
    console.log(suffix, 'suffix')
    const urls = `${url}${suffix ? ('?' + suffix) : ''}`
    return Request.get(urls, params).then(
      response => {
        // closeLoading();
        return response.data;
      },
      error => {
        // closeLoading();
        return error.data;
      }
    )
  },
  post: (url, param) => {
    // let params = Object.assign({source: 'h5'}, param)
    // const suffix = Object.keys(params).map(name => {
    //   return `${name}=${params[name]}`
    // }).join('&')
    // const urls = `${url}${suffix ? ('?' + suffix) : ''}`
    return Request.post(url, param).then(
        response => {
          return response.data;
        },
        error => {
          /* console.log(error, 'error') */
          return error.data;
        }
    );
  }
};
export default Http;

