/*
 * @Author: zsk
 * @Date: 2023-02-10 09:30:43
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-04-03 17:31:44
 */
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.originalPus
VueRouter.prototype.push = function push(location) {
  return originalPush.calll(this, location).catch(err => {
    //  err
    console.log(err, 'err')
  })
}

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */"@V/LayOut.vue"),
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import(/* webpackChunkName: "home" */"@V/IndexView.vue")
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "home" */"@V/LoginView.vue")
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  //  未匹配到路由
  if (to.matched.length === 0) {
    from.name ? next({name: from.name}) : next('/')
  } else if (to.meta.title){
    document.title = to.meta.title
  }
  next()
})

export default router