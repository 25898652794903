/*
 * @Author: zsk
 * @Date: 2023-02-10 09:20:37
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-04-27 10:12:39
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import Http from "../src/api/http";
import Apis from '../src/api/api.js'
import Utils from './plugins/utils'
import showdown from 'showdown'
import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-dark.css'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$http = Http; //  请求
Vue.prototype.$apis = Apis; //  api
Vue.prototype.$util = Utils;  //  全局方法
Vue.use(showdown)
Vue.directive("highlight", function(el) {
  let blocks = el.querySelectorAll("pre code")
  blocks.forEach((block) => {
    hljs.highlightElement(block)
  })
})

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify
}).$mount('#app')
