/*
 * @Author: zsk
 * @Date: 2023-03-31 14:44:00
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-04-04 11:59:22
 */
const apis = {
  'chat': '/chat',
  'login': '/login'
}
export default apis